import React, { useEffect } from 'react';
import useBatchState from '../hooks/useBatchState';
import useStyles from '../hooks/useStyles';
import FormHeading from './FormHeading';
import Button from './button/Button';
import FormError from './form/FormError';
import PasswordField from './form/PasswordField';
import TextField from './form/TextField';
import Checkbox from './form/Checkbox';
import TextInfo from './form/TextInfo';
import { getQaKey as qa } from '../util/qa';
import { string, any, bool } from 'prop-types';
import { NAME_MAX_LENGTH, PASSWORD_REGEX, PASSWORD_MESSAGES, PATH, LOGIN_PATH } from '../consts';
import { useCreateIdentityAndMembership, useCreateIdentity } from '../util/api';

CreateAccountForm.propTypes = {
	email: string,
	error: any,
	isOrgInvite: bool,
	organizationName: string,
	token: string,
	emailDisabled: bool,
	organizationId: string
};

CreateAccountForm.defaultProps = {
	emailDisabled: false
};

const MESSAGE = {
	createBtn: 'Create account',
	emailLabel: 'Your email',
	nameLabel: 'Your name',
	namePlaceholder: 'Your full name',
	passwordLabel: 'Password',
	privacyPolicyLink: 'Privacy Policy',
	privacyPolicyPrefix: 'I have read the',
	privacyPolicyRequired: 'Please confirm you have read our Privacy Policy.',
	signIn: 'Already have an account? Sign in',
	tcsLink: 'Terms & Conditions',
	tcsPrefix: 'I agree to the',
	tcsRequired: 'Please confirm you agree to our Terms & Conditions.'
};

export default function CreateAccountForm({
	email: propsEmail,
	error,
	emailDisabled,
	token: inviteToken,
	organizationName,
	isOrgInvite,
	organizationId
}) {
	const createIdentityAndMembership = useCreateIdentityAndMembership(organizationId);
	const createIdentity = useCreateIdentity();
	const [{ loading, data }, submitAction] = isOrgInvite ? createIdentityAndMembership : createIdentity;
	const classes = useStyles(styles);
	const [
		{ name, password, email, tcsChecked, privacyPolicyChecked, formatError, hasSubmitted },
		setState
	] = useBatchState({
		email: propsEmail,
		formatError: '',
		name: '',
		password: '',
		tcsChecked: false,
		privacyPolicyChecked: false,
		hasSubmitted: false
	});

	const redirectPath = `${LOGIN_PATH}${!isOrgInvite ? '?from=/organization/create' : ''}`;

	const showPrivPolicyError = hasSubmitted && !privacyPolicyChecked;
	const showTcsError = hasSubmitted && !tcsChecked;

	useEffect(() => {
		if (data.accountId || data.id) window.location.replace(redirectPath);
	}, [data, redirectPath]);

	const onChange = ({ target: { name, value } }) => setState({ [name]: value });

	const onChangeCheckbox = ({ target: { name, checked } }) => setState({ [`${name}Checked`]: checked });

	const onPasswordValidityChange = (_, { patternMismatch }) => {
		setState({ formatError: patternMismatch ? PASSWORD_MESSAGES.formatError : '' });
	};

	const onSubmit = e => {
		e.preventDefault();
		setState({ hasSubmitted: true });
		e.target.checkValidity() &&
			tcsChecked &&
			privacyPolicyChecked &&
			submitAction({
				fullname: name,
				email,
				password,
				inviteToken
			});
	};

	return (
		<>
			<FormHeading
				css={classes.heading}
				heading={isOrgInvite ? `Join ${organizationName || 'Organization'}` : 'Create an account'}
			/>
			<form noValidate onSubmit={onSubmit} css={classes.form}>
				<FormError error={error} />
				<TextField
					name="name"
					css={classes.input}
					maxLength={NAME_MAX_LENGTH}
					label={MESSAGE.nameLabel}
					placeholder={MESSAGE.namePlaceholder}
					autoComplete="name"
					value={name}
					onChange={onChange}
					disableValidation={!hasSubmitted}
					required
					{...qa('input:name')}
				/>
				<TextField
					name={'email'}
					css={classes.input}
					label={MESSAGE.emailLabel}
					onChange={onChange}
					type="email"
					value={email}
					disabled={emailDisabled}
					{...qa('input:email')}
				/>
				<PasswordField
					name="password"
					css={classes.marginExtra}
					maxLength={NAME_MAX_LENGTH}
					pattern={PASSWORD_REGEX}
					autoComplete="new-password"
					disableValidation={!hasSubmitted}
					required
					value={password}
					onChange={onChange}
					placeholder={PASSWORD_MESSAGES.placeholder}
					label={MESSAGE.passwordLabel}
					onValidityChange={onPasswordValidityChange}
					errorMessage={formatError}
					{...qa('input:password')}
				/>
				<Checkbox
					name="privacyPolicy"
					css={[classes.checkbox, showPrivPolicyError && classes.checkboxError]}
					checked={privacyPolicyChecked}
					onChange={onChangeCheckbox}
					{...qa('input:privacy-policy')}>
					<span className="caption no-margin">
						{MESSAGE.privacyPolicyPrefix}{' '}
						<a href={PATH.privacy} target="_blank" rel="noopener noreferrer" {...qa('link:privacy')}>
							{MESSAGE.privacyPolicyLink}
						</a>
					</span>
				</Checkbox>
				{showPrivPolicyError && (
					<TextInfo validity={false} css={classes.errorMessage}>
						{MESSAGE.privacyPolicyRequired}
					</TextInfo>
				)}
				<Checkbox
					name="tcs"
					css={[classes.checkbox, showTcsError && classes.checkboxError]}
					checked={tcsChecked}
					onChange={onChangeCheckbox}
					{...qa('input:tcs')}>
					<span className="caption no-margin">
						{MESSAGE.tcsPrefix}{' '}
						<a href={PATH.terms} target="_blank" rel="noopener noreferrer" {...qa('link:terms')}>
							{MESSAGE.tcsLink}
						</a>
					</span>
				</Checkbox>
				{showTcsError && (
					<TextInfo validity={false} css={classes.errorMessage}>
						{MESSAGE.tcsRequired}
					</TextInfo>
				)}
				<Button type="submit" loading={loading} css={classes.submit} qaKey="btn:create-account">
					{MESSAGE.createBtn}
				</Button>
			</form>
		</>
	);
}

const styles = ({ css }) => ({
	heading: css`
		margin-bottom: 32px;
	`,
	form: css`
		width: 360px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 16px;
	`,
	input: css`
		margin-bottom: 16px;
	`,
	marginExtra: css`
		margin-bottom: 24px;
	`,
	checkbox: css`
		margin-bottom: 8px;
	`,
	checkboxError: css`
		margin-bottom: 0;
	`,
	errorMessage: css`
		margin-bottom: 8px;
	`,
	submit: css`
		margin-top: 24px;
	`,
	link: css`
		text-align: center;
	`
});
