import React from 'react';
import useStyles from '../hooks/useStyles';
import { string } from 'prop-types';
import { INVITE_EMAIL } from '../consts';

InviteInvalid.propTypes = {
	orgName: string
};

const MESSAGE = {
	orgInvitePrefix: "You've been invited to join",
	orgInviteSubline1: 'This invitation has expired  :(',
	orgInviteSubline2: 'Please contact your administrator to issue a new one.',
	errorHead: 'Sorry, that invite is no longer available :(',
	errorSublinePrefix: 'Please contact',
	errorSublineSuffix: 'for a new invite.'
};

export default function InviteInvalid({ orgName }) {
	const classes = useStyles(styles);

	return orgName ? (
		<div css={classes.content}>
			<p>
				{`${MESSAGE.orgInvitePrefix} `}
				<span css={classes.title} className={'h1'}>
					{orgName}
				</span>
			</p>
			<p css={classes.text}>
				{MESSAGE.orgInviteSubline1}
				<br />
				{MESSAGE.orgInviteSubline2}
			</p>
		</div>
	) : (
		<div css={classes.errorContainer}>
			<h1 css={classes.heading}>{MESSAGE.errorHead}</h1>
			<p>
				{`${MESSAGE.errorSublinePrefix} `}
				<a href={`mailto:${INVITE_EMAIL}`}>{INVITE_EMAIL}</a>
				{` ${MESSAGE.errorSublineSuffix}`}
			</p>
		</div>
	);
}

function styles({ css, theme: { font } }) {
	return {
		content: css`
			text-align: center;
		`,
		title: css`
			margin: 8px 0;
			display: block;
			text-align: center;
		`,
		text: css`
			margin-top: 24px;
		`,
		errorContainer: css`
			text-align: center;
		`,
		heading: css`
			font-size: 3.6rem;
			font-weight: ${font.weight.light};
			margin-bottom: 16px;
		`
	};
}
