import React from 'react';
import ErrorView from './ErrorView';

const MESSAGE = {
	title: `We'll be back shortly`,
	message: 'Service is temporarily unavailable.'
};

export default function QueryErrorView({ ...rest }) {
	return <ErrorView {...rest} {...MESSAGE} />;
}
