import React from 'react';

CheckIcon.defaultProps = {
	label: 'Tick Symbol'
};

export default function CheckIcon(props) {
	return (
		<svg viewBox="0 0 24 24" role="presentation" {...props}>
			<path
				d="M10.1,17.2L18,5.8c0.3-0.4,0.8-0.5,1.3-0.2c0,0,0,0,0,0l0,0c0.4,0.3,0.5,0.9,0.2,1.3l-8.4,12.2
				c-0.3,0.5-0.9,0.6-1.4,0.3c0,0-0.1-0.1-0.1-0.1l-4.9-4.4c-0.4-0.3-0.4-0.9-0.1-1.3l0,0c0.3-0.4,0.9-0.4,1.3-0.1c0,0,0,0,0,0
				L10.1,17.2z"
			/>
		</svg>
	);
}
