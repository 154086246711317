import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import CreateAccountForm from '../components/CreateAccountForm';
import InviteInvalid from '../components/InviteInvalid';
import { shape, string } from 'prop-types';
import qs from 'query-string';
import MetaData from '../components/MetaData';
import { useInviteTokenDetails, useHasSession } from '../util/api';
import PageLoader from '../components/loaders/PageLoader';
import { ID_SERVICE_PATH, TOKEN_STATES } from '../consts';
import JoinForm from '../components/JoinForm';
import QueryErrorView from '../components/QueryErrorView';

Join.propTypes = {
	location: shape({
		search: string.isRequired
	}).isRequired
};

export default function Join({ location: { search } }) {
	const param = qs.parse(search || '');
	return (
		<Layout>
			<MetaData title="Join" />
			<JoinContent token={param.invite} />
		</Layout>
	);
}

JoinContent.propTypes = {
	token: string.isRequired
};

function JoinContent({ token }) {
	const [
		{
			data: { accountId, email, organizationName, status, hasAccount, organizationId },
			error,
			loading
		},
		getInvite
	] = useInviteTokenDetails(token);
	const [{ error: sessionError, loading: sessionLoading, hasSession }, getSession] = useHasSession();
	const isOrgInvite = !!organizationName;
	const invitePending = status && status === TOKEN_STATES.pending;

	useEffect(() => {
		token && getInvite();
		getSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (error || sessionError) {
		return <QueryErrorView />;
	} else if (loading || sessionLoading || sessionLoading === undefined) {
		return <PageLoader />;
	} else if (hasSession) {
		// TODO: handle case when wrong account is logged in
		if (invitePending) {
			/* show join page */
			return (
				<JoinForm
					accountId={accountId}
					organizationId={organizationId}
					organizationName={organizationName}
					token={token}
				/>
			);
		} else {
			/* show expired page */
			return <InviteInvalid orgName={organizationName} />;
		}
	} else if (hasAccount) {
		/* redirect to login page for signed out user */
		if (hasAccount && !hasSession && token) {
			const url = createSSORedirectURL(token);
			window.location.replace(url);
		}
	} else if (!hasAccount && token) {
		/* show create account page */
		return (
			<CreateAccountForm
				email={email}
				isOrgInvite={isOrgInvite}
				emailDisabled
				organizationName={organizationName}
				organizationId={organizationId}
				token={token}
			/>
		);
	} else {
		return <InviteInvalid orgName={organizationName} />;
	}
	return null;
}

function createSSORedirectURL(token) {
	const idServicePath = ID_SERVICE_PATH.endsWith('.local:8000/api') ? 'https://id.mtribes.dev/api' : ID_SERVICE_PATH;
	return `${idServicePath}/external/challenge_for_invite?invite=${token}`;
}
