import React, { useEffect } from 'react';
import useStyles from '../hooks/useStyles';
import FormHeading from './FormHeading';
import Button from './button/Button';
import FormError from './form/FormError';
import { string } from 'prop-types';
import { useCreateMembership } from '../util/api';
import { LOGIN_PATH } from '../consts';

JoinForm.propTypes = {
	accountId: string,
	organizationId: string,
	organizationName: string,
	token: string
};

const MESSAGE = {
	joinBtn: 'Join',
	heading: `You've been invited to join`
};

export default function JoinForm({ accountId, organizationId, organizationName, token }) {
	const [{ error, loading, status }, submitAction] = useCreateMembership(organizationId, accountId);
	const classes = useStyles(styles);

	useEffect(() => {
		if (status === 201) window.location.replace(LOGIN_PATH);
	}, [status]);

	const onSubmit = e => {
		e.preventDefault();
		submitAction({ token });
	};

	return (
		<>
			<FormHeading
				css={classes.heading}
				heading={MESSAGE.heading}
				innerCss={{ heading: classes.innerHeading, subHeading: classes.subHeading }}
				subHeading={organizationName}
			/>
			<form onSubmit={onSubmit} css={classes.form}>
				{error && <FormError error={error} />}
				<Button type="submit" loading={loading} css={classes.submit} qaKey="btn:join">
					{MESSAGE.joinBtn}
				</Button>
			</form>
		</>
	);
}

const styles = ({
	css,
	theme: {
		font: { h1 }
	}
}) => ({
	heading: css`
		margin-bottom: 32px;
	`,
	innerHeading: css`
		font-size: 1.4rem;
	`,
	subHeading: css`
		${h1}
		margin-bottom: 0;
	`,
	form: css`
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	submit: css`
		width: 120px;
	`
});
