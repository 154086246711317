import { object, node, bool, string } from 'prop-types';
import React from 'react';
import { getQaKey as qa } from '../../util/qa';
import CheckIcon from '../icons/CheckIcon';
import useStyles from '../../hooks/useStyles';

Checkbox.propTypes = {
	className: string,
	innerCss: object,
	name: string,
	checked: bool,
	children: node,
	tabDisabled: bool,
	showCheckboxLast: bool
};

Checkbox.defaultProps = {
	showCheckboxLast: false,
	innerCss: {}
};

export default function Checkbox({
	className,
	innerCss,
	children,
	name,
	tabDisabled,
	checked,
	showCheckboxLast,
	...inputProps
}) {
	const classes = useStyles(styles);
	return (
		<label
			css={[classes.label, innerCss.label, inputProps.disabled && classes.disabled]}
			className={className}
			{...qa(`checkbox:${name}:${checked}`)}>
			<input
				{...inputProps}
				name={name}
				checked={checked}
				tabIndex={tabDisabled ? -1 : 0}
				css={classes.input}
				className={innerCss.input}
				type="checkbox"
			/>
			<div css={[classes.checkbox, innerCss.checkbox]} className="Checkbox-box">
				<CheckIcon css={classes.check} />
			</div>
			{children}
		</label>
	);
}

function styles({ css, theme: { color, getFocusStyles } }) {
	return {
		label: css`
			position: relative;
			display: inline-flex;
			align-items: center;
			opacity: 1;
			margin-right: 8px;
			transition: opacity 0.2s;
			cursor: pointer;
		`,
		disabled: css`
			opacity: 0.5;
		`,
		checkboxContainer: css`
			display: inline-flex;
		`,
		checkbox: css`
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 8px;
			width: 16px;
			height: 16px;
			border-radius: 4px;
			fill: transparent;
			transition: color 0.2s;
			padding: 4px 2px;
			border: 1px solid ${color.muted};
		`,
		check: css`
			width: 10px;
			flex-shrink: 0;
		`,
		input: css`
			opacity: 0;
			width: 0;
			&:focus + .Checkbox-box,
			&:hover + .Checkbox-box,
			&:checked + .Checkbox-box {
				background-color: ${color.primaryFaint};
				border: 1px solid ${color.primary};
			}

			&:focus + .Checkbox-box {
				${getFocusStyles()};
			}

			&:checked + .Checkbox-box {
				fill: ${color.white};
				background-color: ${color.primary};
				border: solid 2px ${color.primary};
			}
			&:hover:disabled + .Checkbox-box {
				box-shadow: none;
			}
		`
	};
}
